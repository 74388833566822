import { gql } from "@apollo/client";

export const killsQuery = gql`
  query GetLatestGuildKills(
    $id: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $from: Long
    $to: Long
    $soloOnly: Boolean
  ) {
    kills(
      killerGuildId: $id
      first: $first
      last: $last
      before: $before
      after: $after
      from: $from
      to: $to
      soloOnly: $soloOnly
    ) {
      totalCount
      nodes {
        id
        time
        position {
          zone {
            id
            name
            __typename
          }
          __typename
        }
        scenario {
          id
          name
          __typename
        }
        attackers {
          level
          renownRank
          damagePercent
          character {
            id
            career
            name
            __typename
          }
          guild {
            id
            name
            __typename
          }
          __typename
        }
        victim {
          level
          renownRank
          character {
            id
            career
            name
            __typename
          }
          guild {
            id
            name
            __typename
          }
          __typename
        }
        __typename
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
        __typename
      }
      __typename
    }
  }
`;
