import "./App.css";

import GuildChars from "./GuildChars";

function App() {
  return (
    <div className="main">
      <div className="container mx-auto">
        <img className="w-60 mx-auto" src={`/images/RET_des-order_PNG.png`} />
        <div className="mx-auto md:flex justify-between relative md:-top-40 max-w-5xl py-5">
          <GuildChars id={3603} name={`Retribution`} />
          <GuildChars id={2905} name={`Devastation`} />
        </div>
      </div>
    </div>
  );
}

export default App;
